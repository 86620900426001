<template>
    <div>
        <v-list class="mx-2 mt-4 mx-md-4 border border-secondary blue lighten-5" subheader three-line
            v-for="(row, index) in itemData" :key="index">
            <v-list-item>
                <v-list-item-avatar size="80">
                    <ImageTemplate circle :src="row.image"></ImageTemplate>
                </v-list-item-avatar>
                <v-list-item-content class="col-4" v-if="row">
                    <v-list-item-title class="font-size-16">
                        <b>{{ row.related_name }}</b>
                        <br /><b>#{{ row.barcode }}</b>
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-size-14">
                        <img style="max-width: 75%; height: 30px" :src="row.barcode_image" :alt="row.pico_barcode" />
                        <pre class="mb-0 mt-2 barcode-text text-center bold-800 w-75">{{ row.pico_barcode }}</pre>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="col-6 d-flex" v-if="row">
                    <v-list-item-subtitle class="font-size-14" v-if="row.retired_date_formatted">
                        <b>Retired Date :</b>
                        <span class="fw-500 mr-1"> {{ row.retired_date_formatted }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="font-size-14" v-if="row.quantity && row.quantity > 0">
                        <b>Quantity :</b>
                        <span class="fw-500 mr-1"> {{ row.quantity }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="font-size-14">
                        <b>Approval Required :</b>
                        <template v-if="row.aprrove_transfer == 1">
                            <span class="fw-500 mr-1"> Yess</span>
                        </template>
                        <template v-else>
                            <span class="fw-500 mr-1"> No</span>
                        </template>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="font-size-14">
                        <b>Retire Reason :</b>
                        <span class="fw-500 mr-1"> {{ row.retire_reason }}</span>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="col-2 justify-end" v-if="row">
                    <v-list-item-action-text class="font-size-16">
                        <v-tooltip v-if="false" top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attr }">
                                <v-btn v-on="on" v-bind="attr" depressed color="blue darken-4 white--text" class="mr-3"
                                    fab v-on:click="updateDialog(row.uuid, 'retire')" small>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attr }">
                                <v-btn v-on="on" v-bind="attr" depressed
                                    v-on:click="deleteConfirm(row.uuid, row.related_name)" color="red white--text" fab
                                    small>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <Dialog :dialog="deleteDialog" :dialog-width="640">
            <template v-slot:title> Delete Cart Item</template>
            <template v-slot:body>
                <v-row class="delete-dialog">
                    <v-col md="2" class="py-0 text-right my-auto">
                        <span class="svg-icon svg-icon-lg delete-confirm-icon">
                            <!--begin::Svg Icon-->
                            <inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
                            <!--end::Svg Icon-->
                        </span>
                    </v-col>
                    <v-col md="10" class="py-0 my-auto">
                        <p class="btx-p m-0">
                            Are you sure you want to remove
                            <span class="red--text font-bolder lighten-1">{{ deleteText }}</span> from cart ?
                        </p>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:action>
                <v-btn class="white--text" :loading="deleteButton" :disabled="deleteButton" depressed
                    color="red lighten-1" tile v-on:click="deleteCartRow">
                    Yes! Remove
                </v-btn>
                <v-btn depressed tile :disabled="deleteButton" v-on:click="deleteDialog = false">
                    No, Close
                </v-btn>
            </template>
        </Dialog>
    </div>
</template>
<script>
import ImageTemplate from "@/view/components/Image";
import { CART_COUNT } from "@/core/services/store/config.module";
import { DeleteCart } from "@/core/lib/cart.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog.vue";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
    name: "Issue",
    components: {
        ImageTemplate,
        Dialog
    },
    data() {
        return {
            deleteDialog: false,
            deleteUUID: null,
            deleteText: null,
            deleteButton: false,
        }
    },
    props: {
        itemData: {
            type: Array,
            default: new Array(),
        },
    },
    mounted() {
        console.log(this.itemData, 'sdfgdfg')

    },
    methods:
    {
        deleteConfirm(uuid, assetName) {
            this.deleteDialog = true;
            this.deleteUUID = uuid;
            this.deleteText = assetName;
        },
        deleteCartRow() {
            const _this = this;
            this.deleteButton = true;
            DeleteCart(this.deleteUUID)
                .then(() => {
                    _this.deleteDialog = false;
                    _this.$store.dispatch(CART_COUNT);
                    EventBus.$emit("refresh-cart");
                })
                .catch((error) => {
                    _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
                })
                .finally(() => {
                    _this.pageLoading = false;
                    this.deleteButton = false;
                });
        },
    }
};
</script>
